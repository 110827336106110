<template>
    <v-app>
        <v-container>
            <v-btn
                href="https://slack.com/oauth/v2/authorize?client_id=8076345695156.8073755746819&scope=app_mentions:read,chat:write,chat:write.public,files:read,files:write,im:history,usergroups:read,users.profile:read,users:read.email,users:read,reactions:read,commands,emoji:read,incoming-webhook,channels:history,groups:history&user_scope="
                target="_blank" color="primary" outlined>
                <img alt="Add to Slack" height="40" width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
            </v-btn>
        </v-container>

    </v-app>
</template>

<script>
import user_profile_mixin from "../mixins/user_profile.js";
const axios = require('axios').default;

export default {
    data() {
        return {
            user_profile: { org_role_internal: "organization_member" },
        }
    },
    mixins: [user_profile_mixin],
    methods: {

    },
    created() {
        this.fetchUserProfile();
    }
}
</script>