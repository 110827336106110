import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import AboutUs from "../views/AboutUs.vue";
import Logout from "../views/Logout.vue";
import NotFound from "../views/NotFound.vue";
import Chatbot from '../views/Chatbot.vue';
import FormInput from "../views/FormInput.vue";
import TaskValidation from '../views/TaskValidation.vue';
import Projects from '../views/Projects.vue';
import Project from '../views/Project.vue';
import Task from '../views/Task.vue';
import Dashboard from "../views/Dashboard.vue";
import Calendar from "../views/Calendar.vue";
import Analytics from "../views/Analytics.vue";
import Messages from "../views/Messages.vue";
import ProjectDashboard from "../views/ProjectDashboard.vue";
import Integrations from '../views/Integrations.vue';
import ClickUpOAuth from '../views/ClickUpOAuth.vue';
import AIWiseTrack from "../views/AIWiseTrack.vue";
import store from '../store'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      appBar: 'hide',
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      appBar: 'hide',
    }
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: AboutUs,
    meta: {
      appBar: 'hide',
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'visible',
    }
  },
  {
    path: '/project/:project_id',
    name: 'Project',
    component: Project,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'collapsed',
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      auth: false,
      appBar: 'hide',
    },
  },
  {
    path: "/chatbot/:project_id",
    name: "chatbot",
    component: Chatbot,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'hide',
    }
  },
  {
    path: "/forminput/:project_id",
    name: "forminput",
    component: FormInput,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'hide',
    }
  },
  {
    path: "/taskvalidation/:project_id",
    name: "taskvalidation",
    component: TaskValidation,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'hide',
    }
  },
  {
    path: "/project/:project_id/task/:task_id",
    name: "task_details",
    component: Task,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'collapsed',
    }
  },
  {
    path: "/integrations",
    name: "integrations",
    component: Integrations,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'collapsed',
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'visible',
    }
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'visible',
    }
  },
  {
    path: "/analytics",
    name: "analytics",
    component: Analytics,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'visible',
    }
  },
  {
    path: "/messages",
    name: "messages",
    component: Messages,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'visible',
    }
  },
  {
    path: "/project_dashboard/:project_id",
    name: "project_dashboard",
    component: ProjectDashboard,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'collapsed',
    }
  },
  {
    path: "/oauthclickup",
    name: "oauth_clickup",
    component: ClickUpOAuth,
    meta: {
      auth: true,
      firstLogin: true,
      appBar: 'hide',
    }
  },
  {
    path: "/ai-wisetrack",
    name: "ai-wisetrack",
    component: AIWiseTrack,
    meta: {
      appBar: 'hide',
    }
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
    meta: {
      appBar: 'hide',
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.firstLogin)) {
    // This route doesn't allow that the user didn't update the password, redirect to the login page
    if (store.getters.isFirstLogin) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.router = router;

export default Vue.router;
